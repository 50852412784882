import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Text, Box, useToast, Spinner, Flex } from '@chakra-ui/react';
import type { FormEvent } from 'react';

import BackendApi from 'src/api/BackendApi';

import './FormIdentity.css';

export default () => {
  const toast = useToast();
  const { t } = useTranslation();
  const [isFetching, setFetching] = useState(false);
  const [htmlTemplate, setHtmlTemplate] = useState<string | null>(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    setFetching(true);
    BackendApi.getFormConfig(id)
      .then((result) => {
        setHtmlTemplate(result.htmlData);
      })
      .finally(() => setFetching(false));
  }, []);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmitting(true);

    const formElement = event.target as HTMLFormElement;
    const formData = new FormData(formElement);
    const values: Record<string, string> = {};
    formData.forEach((value, name) => {
      values[name] = value.toString();
    });

    try {
      const response = await BackendApi.sendForm(id, values);

      if (response.status === 200) {
        toast({
          title: t('toasts.form_created'),
          status: 'success',
          duration: 2000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: t('toasts.form_failed'),
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    } finally {
      setSubmitting(false);
    }
  };

  if (isFetching) {
    return (
      <Flex className="contentWrapper" alignItems="center" justifyContent="center" direction="column">
        <Spinner size="xl" color="blue.500" />
        <Text mt={4} fontSize="xl" fontWeight="bold">
          Loading form template...
        </Text>
      </Flex>
    );
  }

  if (htmlTemplate === null) {
    return (
      <Flex className="contentWrapper" alignItems="center" justifyContent="center" direction="column">
        <Text fontSize="xl" fontWeight="bold">
          Form is not available
        </Text>
        <Text fontSize="md">Contact customer support</Text>
      </Flex>
    );
  }

  return (
    <Box maxW="xl" overflow="hidden" m="auto" padding="2">
      <div className="no-inherit formContainer">
        <form onSubmit={handleSubmit} dangerouslySetInnerHTML={{ __html: htmlTemplate }} />
      </div>
      <Box
        display={isSubmitting ? 'flex' : 'none'}
        zIndex="9999"
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9999,
          background: 'rgba(255, 255, 255, .8)'
        }}
      >
        <Flex direction="column" alignItems="center">
          <Spinner size="xl" color="blue.500" />
          <Text mt={4} fontSize="xl" fontWeight="bold">
            Submitting...
          </Text>
        </Flex>
      </Box>
    </Box>
  );
};

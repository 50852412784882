import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Box, Alert, AlertIcon } from '@chakra-ui/react';

import FeatureFlags from 'src/api/FeatureFlags';
import ApiConfig from 'src/api/ApiConfig';
import { useAppThunkDispatch } from 'src/store/store';
import { logIn } from 'src/store/actions/authActions';

import './LogIn.css';

export default () => {
  const { t } = useTranslation();
  const dispatch = useAppThunkDispatch();
  const [isLoading, setLoading] = useState(false);
  const isEID = FeatureFlags.isFlagOn('ENABLE_PORTAL_EID_AUTH');
  const isDesk = FeatureFlags.isFlagOn('ENABLE_PORTAL_DESK_AUTH');

  return (
    <div className="logIn__wrapper">
      {isDesk && (
        <Box width="100%" padding="2">
          <Button
            colorScheme="blue"
            variant="solid"
            size="lg"
            width="100%"
            className="deskLoginButton"
            onClick={() =>
              (window.location = `${ApiConfig.getConfig().SUPPORT_FRONTEND}/login?redirectUrl=${
                window.location.href
              }` as unknown as Location)
            }
          >
            {t('buttons.desk_login')}
          </Button>
        </Box>
      )}

      {isEID && (
        <Box width="100%" padding="2">
          <Button
            isLoading={isLoading}
            loadingText="Submitting"
            colorScheme="teal"
            variant="outline"
            size="lg"
            width="100%"
            className="eidLoginButton"
            onClick={() => {
              setLoading(true);
              dispatch(logIn({ originalUrl: window.location.href, type: 'eid' }));
            }}
          >
            {t('buttons.eid')}
          </Button>
        </Box>
      )}

      {!isEID && !isDesk && (
        <Alert status="info" borderRadius={8}>
          <AlertIcon />
          {t('labels.no_provider_enabled')}
        </Alert>
      )}
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactCountryFlag from 'react-country-flag';
import { getI18n, useTranslation } from 'react-i18next';
import { Menu, MenuButton, MenuList, MenuItem, IconButton, useToast } from '@chakra-ui/react';

import ApiConfig from 'src/api/ApiConfig';
import { getLanguages } from 'src/utils/language';
import { setLanguage } from 'src/store/actions/personalDataActions';
import { useAppThunkDispatch } from 'src/store/store';
import type { Language } from 'src/types/language';
import type { State } from 'src/types/state';

const LanguageSelect = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const dispatch = useAppThunkDispatch();
  const languages = getLanguages();
  const { i18n } = useTranslation();
  const userDefaultLanguage = useSelector((state: State) => state.personalData?.profile.defaultLanguage);
  const environmentDefaultLanguage = ApiConfig.getConfig().DEFAULT_LANGUAGE || i18n.language;
  const [dropdownFlag, setDropdownFlag] = useState(
    languages.find((language) => language.id === environmentDefaultLanguage)?.flag
  );
  const [isChangingLanguage, setChangingLanguage] = useState(false);

  const handleSelect = async (language: Language) => {
    try {
      setChangingLanguage(true);
      if (userDefaultLanguage) {
        await dispatch(
          setLanguage({
            langId: language.id
          })
        );
      }

      setDropdownFlag(language.flag);
      getI18n().changeLanguage(language.id);
      setChangingLanguage(false);
    } catch (error) {
      setChangingLanguage(false);
      toast({
        title: t('toasts.language_change_failed'),
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    }
  };

  useEffect(() => {
    if (userDefaultLanguage) {
      setDropdownFlag(languages.find((language) => language.id === userDefaultLanguage)?.flag);
      getI18n().changeLanguage(userDefaultLanguage);
    }
  }, [userDefaultLanguage]);

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        icon={<ReactCountryFlag countryCode={dropdownFlag || ''} svg />}
        colorScheme="teal"
        fontWeight={500}
        size="md"
        mr={2}
        className="languageButton"
        disabled={isChangingLanguage}
      />

      <MenuList>
        {languages.map((language) => (
          <MenuItem key={language.id} onClick={() => handleSelect(language)}>
            <ReactCountryFlag countryCode={language.flag} svg style={{ marginRight: '12px' }} />
            <span>{language.name}</span>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default LanguageSelect;

import React from 'react';
import { Grid, GridItem, Flex, useBreakpointValue } from '@chakra-ui/react';

import Sidebar from '../DeskUserView/Sidebar';
import FramePreview from '../Frame/FramePreview';

const Page = ({ children }: { children: React.ReactNode }) => {
  const sidebarColumnSpan = useBreakpointValue({ base: 1, lg: 2 });
  const contentColumnSpan = useBreakpointValue({ base: 1, lg: 8 });

  return (
    <div className="container pageWrapper">
      {useBreakpointValue({
        base: (
          <Flex direction="column">
            <GridItem colSpan={sidebarColumnSpan}>
              <Sidebar />
            </GridItem>
            <GridItem colSpan={contentColumnSpan} style={{ margin: '1rem' }}>
              {children}
            </GridItem>
          </Flex>
        ),
        lg: (
          <Grid templateColumns="repeat(10, 1fr)" gap={4}>
            <GridItem colSpan={sidebarColumnSpan}>
              <Sidebar />
            </GridItem>
            <GridItem colSpan={contentColumnSpan} style={{ margin: '1rem' }}>
              {children}
            </GridItem>
          </Grid>
        )
      })}

      <FramePreview />
    </div>
  );
};

export default Page;

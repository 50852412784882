import axios from 'axios';
import { Cookies } from 'react-cookie';
import queryString from 'query-string';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import type { AxiosInstance, AxiosError } from 'axios';

import ApiConfig from './ApiConfig';
import store from '../store/store';
import FeatureFlags from './FeatureFlags';
import { authFail, authSuccess, logOut } from '../store/actions/authActions';
import { fetchViewConfig, fetchConfig } from '../store/actions/configActions';
import { getSearchParamsWithTag } from '../utils';
import type { LoginResult } from '../types/Auth';
import type { PersonalData } from 'src/types/Profile';
import type { PortalConfig, ViewConfig } from '../types/Config';

const PORTAL_API_URL = () => ApiConfig.getConfig().PORTAL_API_URL;

class BackendApi {
  private NOT_ACCEPTABLE = 406;
  public axios: AxiosInstance = axios.create({
    withCredentials: true
  });

  constructor() {
    this.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response && error.response.status && error.response.status === this.NOT_ACCEPTABLE) {
          store.dispatch<any>(logOut({ type: 'automatic' }));
        }

        return Promise.reject(error);
      }
    );

    const refreshAuthLogic = async () => {
      return this.refreshAuth().then(() => {
        return Promise.resolve();
      });
    };
    createAuthRefreshInterceptor(this.axios, refreshAuthLogic, { statusCodes: [401, 403] });
  }

  public init = () => {
    store.dispatch<any>(fetchConfig());
    if (!FeatureFlags.isFlagOn('ENABLE_PORTAL_DESK_AUTH') && !FeatureFlags.isFlagOn('ENABLE_PORTAL_EID_AUTH')) {
      return;
    }

    const cookies = new Cookies();
    const logginCookie = cookies.get('loggedIn');

    if (logginCookie) {
      store.dispatch(authSuccess());

      if (!cookies.get('EID')) {
        store.dispatch<any>(fetchViewConfig());
      }
    } else {
      store.dispatch(authFail());
    }
  };

  private refreshAuth = async () => this.axios.post(`${PORTAL_API_URL()}/auth/refresh`);

  public eidLogin = async (originalUrl: string) =>
    this.axios
      .post<LoginResult>(`${PORTAL_API_URL()}/auth/login/eid`, {
        originalUrl
      })
      .then((response) => response.data)
      .catch((error: AxiosError) => error.response?.data);

  public deskLogin = async (payload: { username: string; password: string }) =>
    this.axios
      .post<LoginResult>(`${PORTAL_API_URL()}/auth/login/desk`, payload)
      .then((response) => response.data)
      .catch((error: AxiosError) => error.response?.data);

  public logout = async () => this.axios.post(`${PORTAL_API_URL()}/auth/logout`);

  public getEntityByHash = async ({ hash }: { hash?: string }) => {
    let url = `${PORTAL_API_URL()}/entity`;

    if (hash) {
      // ticketType actually not in use, placed for backward compatibility
      url += `/${hash}/ticketType`;
    }

    return this.axios.get(url).then((response) => response.data);
  };

  public getEntityById = async ({ entityId }: { entityId: string }) =>
    this.axios.get(`${PORTAL_API_URL()}/entity/by-id/${entityId}`).then((response) => response.data);

  public getEntityTags = async (entityId: string) =>
    this.axios.get(`${PORTAL_API_URL()}/entity/${entityId}/tags`).then((response) => response.data);

  public getEntityTicketsByTag = async ({ entityId, tag }: { entityId: string; tag: number }) =>
    this.axios.get(`${PORTAL_API_URL()}/tickets/${entityId}/${tag}`).then((response) => response.data);

  public getEntityTickets = async ({ entityId }: { entityId: string }) =>
    this.axios.get(`${PORTAL_API_URL()}/tickets/${entityId}`).then((response) => response.data);

  public getTicketByHash = async (hash?: string) => {
    const entityHash = hash ?? '';
    return this.axios.get(`${PORTAL_API_URL()}/cases/${entityHash}`).then((response) => response.data);
  };

  public updateEntityByHash = async (
    entity: { [key: string]: unknown },
    { hash, ticketId }: { hash?: string; ticketId?: string }
  ) => {
    let url = `${PORTAL_API_URL()}/entity`;

    if (hash) {
      url += `/${hash}`;
      if (ticketId) {
        url += `/${ticketId}`;
      }
    }

    return this.axios.post(url, entity);
  };

  public createNewTicket = async (hash: string | null, data: FormData) => {
    const entityHash = hash ?? '';
    return this.axios.post(`${PORTAL_API_URL()}/cases/${entityHash}`, data);
  };

  public completeMessage = async (ticketId: string, hash?: string) => {
    const entityHash = hash ?? '';
    return this.axios.post(`${PORTAL_API_URL()}/messages/completion/caseId/${ticketId}/entityId/${entityHash}`);
  };

  public uploadComment = async (ticketId: string, data: FormData, hash?: string) => {
    const entityHash = hash ?? '';
    return this.axios.post(`${PORTAL_API_URL()}/messages/caseId/${ticketId}/${entityHash}`, data);
  };

  public getFormConfig = async (name: string) =>
    this.axios
      .get<{ htmlData: string | null }>(`${PORTAL_API_URL()}/config/form/${name}`)
      .then((response) => response.data);

  public getViewConfig = async () =>
    this.axios.get<ViewConfig>(`${PORTAL_API_URL()}/config/view`).then((response) => response.data);

  public getConfig = async () =>
    this.axios.get<PortalConfig>(`${PORTAL_API_URL()}/config`).then((response) => response.data);

  public sendForm = async (id: string, payload: { [key: string]: unknown }) =>
    this.axios.post(`${PORTAL_API_URL()}/form/${id}/formhandler`, payload);

  public searchEntities = async ({
    parameters,
    taskType,
    entityTypes
  }: {
    parameters: { type: string; value: string }[];
    taskType: string;
    entityTypes: string[];
  }) => {
    const searchParams = getSearchParamsWithTag(parameters, { taskType, entityTypes });
    return this.axios.get<Record<string, string>[]>(`${PORTAL_API_URL()}/entities/search${searchParams}`);
  };

  public getSummary = async ({
    parameters,
    taskType,
    entityTypes
  }: {
    parameters: { type: string; value: string }[];
    taskType: string;
    entityTypes: string[];
  }) => {
    const searchParams = getSearchParamsWithTag(parameters, { taskType, entityTypes });
    return this.axios.get<{ summary: (string | number)[]; info: Record<string, unknown> }[]>(
      `${PORTAL_API_URL()}/summary${searchParams}`
    );
  };

  public getPersonalData = async () =>
    this.axios.get<PersonalData>(`${PORTAL_API_URL()}/personalData`).then((response) => response.data);

  public getTicketTypes = async () =>
    this.axios.get<Record<string, unknown>>(`${PORTAL_API_URL()}/ticketTypes`).then((response) => response.data);

  public changeUserLanguage = async (langId: string) => this.axios.patch(`${PORTAL_API_URL()}/setLanguage`, { langId });
}

export default new BackendApi();

import { withRouter } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  useDisclosure,
  useToast,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Alert,
  AlertIcon
} from '@chakra-ui/react';
import 'moment-timezone';
import moment from 'moment';
import { get, set } from 'lodash';
import type { FormEvent } from 'react';
import type { RouteComponentProps } from 'react-router';

import BackendApi from 'src/api/BackendApi';
import LoadingSpinner from 'src/components/helpers/LoadingSpinner';
import { clearSearchParams, getUrlSearchParam } from 'src/utils';
import type { WidgetFormConfig } from 'src/types/Config';

interface FormAttachmentPayload {
  fileName: string;
  contentType: string;
  base64Content: string;
}

interface WindowWithToggleTextarea extends Window {
  toggleTextarea: (event: Event) => void;
}

interface WidgetFormProps extends RouteComponentProps {
  entity: Record<string, unknown>;
  formConfig: WidgetFormConfig;
}

const WidgetForm = ({ entity, formConfig, history }: WidgetFormProps) => {
  const toast = useToast();
  const formRef = useRef<HTMLFormElement | null>(null);
  const location = useLocation();
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: !!getUrlSearchParam(formConfig.formId),
    onClose: () => {
      clearSearchParams();
    }
  });
  const [isSubmitting, setSubmitting] = useState(false);
  const [isFetchingFormTemplate, setFetchingFormTemplate] = useState(true);
  const [formHtml, setFormHtml] = useState<string | null>(null);
  const [rerender, setRerender] = useState<number>(0);

  // Replacing template parameters from source html
  const html = useMemo(() => {
    if (formHtml) {
      let initialHtml = formHtml;
      const regex = /{{(.*?)}}/g;
      const matches = formHtml.match(regex);

      if (matches) {
        matches.forEach((match) => {
          const path = match.replaceAll('{', '').replaceAll('}', '');
          const value = get({ entity }, path) as string;
          initialHtml = initialHtml.replace(match, value);
        });
      }
      return initialHtml;
    }
    return formHtml;
  }, [formHtml]);

  useEffect(() => {
    if (!!getUrlSearchParam(formConfig.formId)) {
      onOpen();
    }
  }, [location]);

  useEffect(() => {
    (window as unknown as WindowWithToggleTextarea).toggleTextarea = function (event: Event) {
      const checkbox = event.target as HTMLInputElement;
      const inputs = document.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>(`[name^="${checkbox.name}_"]`);
      const label = document.querySelector(`label[for="${checkbox.name}_content"]`) as HTMLLabelElement;

      if (checkbox.checked) {
        inputs.forEach((element) => {
          element.style.display = 'block';
        });
        label.style.display = 'block';
      } else {
        inputs.forEach((element) => {
          element.style.display = 'none';
          element.value = '';
        });
        label.style.display = 'none';
      }
    };

    BackendApi.getFormConfig(formConfig.formId)
      .then((response) => setFormHtml(response.htmlData))
      .finally(() => setFetchingFormTemplate(false));
  }, []);

  useEffect(() => {
    // A workaround of the issue with not abling to get elements from dangerouslySetInnerHTML
    setRerender(rerender + 1);
  }, [isOpen]);
  // File Input helper
  useEffect(() => {
    const fileInput = formRef.current?.querySelector('#attachment') as HTMLInputElement;
    const fileName = formRef.current?.querySelector('#fileName');

    const handleChange = () => {
      const files = fileInput.files!;
      if (files.length > 0) {
        const fileNames = [];
        for (let i = 0; i < files.length; i++) {
          fileNames.push(files[i].name);
        }
        if (fileName) fileName.innerHTML = fileNames.join('<br/>');
      } else {
        if (fileName) fileName.textContent = t('labels.no_files_selected');
      }
    };

    const formResetCallback = () => {
      if (fileName) fileName.textContent = t('labels.no_files_selected');
    };

    fileInput?.addEventListener('change', handleChange);
    formRef.current?.addEventListener('reset', formResetCallback);

    return () => {
      fileInput?.removeEventListener('change', handleChange);
      formRef.current?.removeEventListener('reset', formResetCallback);
    };
  }, [rerender]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmitting(true);

    const formElement = event.target as HTMLFormElement;
    const formData = new FormData(formElement);
    const values: Record<string, unknown> = {
      ...(formConfig.defaultData as Record<string, string>),
      base64Attachments: [] as FormAttachmentPayload[]
    };

    // Files handling
    const fileReaderPromises: Promise<void>[] = [];
    formData.forEach((value, name) => {
      if (name === 'attachment') {
        const reader = new FileReader();
        fileReaderPromises.push(
          new Promise<void>((resolve) => {
            reader.onload = (event) => {
              if (event.target?.result && (value as File).name) {
                const base64Content = event.target.result.toString().split(',')[1];
                (values.base64Attachments as FormAttachmentPayload[]).push({
                  fileName: (value as File).name,
                  contentType: (value as File).type,
                  base64Content
                });
              }
              resolve();
            };
          })
        );
        reader.readAsDataURL(value as File);
      } else {
        set(values, name, value);
      }
    });

    /**
     * Custom logic for object entity fields
     * Predefined fields names: 'entityField', 'entityFieldValue', 'nestedFieldProperty'
     */
    if (values['nestedFieldProperty']) {
      const value = values['entityFieldValue'];
      delete values['entityFieldValue'];
      set(values, `entityFieldValue.${values['nestedFieldProperty']}`, value);
      delete values['nestedFieldProperty'];
    }

    // Fixing dates
    const dateFields = formRef.current?.querySelectorAll(
      'input[data-applytimezone="true"]'
    ) as unknown as HTMLInputElement[];
    dateFields.forEach((inputField) => {
      set(
        values,
        inputField.getAttribute('name')!,
        moment(inputField.value).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).utc().unix()
      );
    });

    try {
      await Promise.all(fileReaderPromises);
      const response = await BackendApi.sendForm(formConfig.formId, values);
      if (response.status === 200) {
        toast({
          title: t('toasts.form_created'),
          status: 'success',
          duration: 2000,
          isClosable: true
        });
      }

      formElement.reset();
      onClose();

      if (values['redirectForm']) {
        history.push(`?${values['redirectForm']}=true`);
      }
    } catch (error) {
      toast({
        title: t('toasts.form_failed'),
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    } finally {
      setSubmitting(false);
    }
  };

  const renderModalBody = () => {
    if (isFetchingFormTemplate) {
      return (
        <Alert status="info" borderRadius={8}>
          <AlertIcon />
          {t('labels.loading')}
        </Alert>
      );
    }

    if (html === null) {
      return (
        <Alert status="warning" borderRadius={8}>
          <AlertIcon />
          {t('labels.form_configuration_error')}
        </Alert>
      );
    }

    return (
      <div className="no-inherit formContainer">
        <form ref={formRef} onSubmit={handleSubmit} dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    );
  };

  return (
    <Box mt={4}>
      <Button onClick={onOpen}>{formConfig.title}</Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl" scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{formConfig.title}</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <div className="loadingOverlay" style={{ display: isSubmitting ? 'flex' : 'none' }}>
              <LoadingSpinner />
            </div>
            {renderModalBody()}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default withRouter(WidgetForm);

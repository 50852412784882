import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';
import {
  Button,
  Input,
  FormControl,
  FormLabel,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Stack,
  Image,
  InputGroup,
  Heading,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Divider,
  UnorderedList,
  ListItem,
  InputLeftAddon
} from '@chakra-ui/react';
import { AttachmentIcon, ChatIcon, ExternalLinkIcon, InfoIcon, InfoOutlineIcon, SpinnerIcon } from '@chakra-ui/icons';

import { CommentSection } from './CommentSection';
import BackendApi from './api/BackendApi';
import ApiConfig from './api/ApiConfig';
import { getUrlSearchParam } from './utils';
import type { Comment } from './types/Comment';
import type Ticket from './types/Ticket';
import type { TicketTypeField } from './types/Ticket';

interface TicketListItemProps {
  ticket: Ticket;
  selectedTicket: string | null;

  fetchTickets: () => Promise<void>;
}

export const TicketListItem = (props: TicketListItemProps) => {
  const correctPortalObj = () => {
    const correctDataObj = props.ticket.portalData.find((obj: any) => {
      if (obj) {
        return obj.id === 'productInfo';
      }
    });

    return (correctDataObj.fields ?? []) as TicketTypeField[];
  };

  const [dataObject, setDataObject] = useState(props.ticket.caseDetails);
  const [valuesChecked, setValuesChecked] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  // state never changed
  const [isUpdatingTickets, setIsUpdatingTickets] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [invalidDataArray, setInvalidDataArray] = useState<string[]>([]);
  const [requiredDataArray, setRequiredDataArray] = useState<string[]>([]);
  const [invalidLengthArray, setInvalidLengthArray] = useState<any[]>([]);
  const [modalWarningIgnored, setModalWarningIgnored] = useState<boolean>(false);
  const [hardRequireModalOpen, setHardRequireModalOpen] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const sendData = async () => {
    const hash = getUrlSearchParam('hash');
    let updateObj = dataObject;
    const portalFields = correctPortalObj().map((field) => field.customerPortal.name);
    const updateObjAsArray = Object.entries(updateObj);
    const portalFieldsOnly = updateObjAsArray.filter(([key]) => portalFields.includes(key));
    updateObj = Object.fromEntries(portalFieldsOnly);
    updateObj.entitySubType = 'device';
    setIsSubmitting(false);
    setIsSending(false);
    setValuesChecked(false);
    const result = await BackendApi.updateEntityByHash(updateObj, { hash, ticketId: props.ticket.id });
    if (result.status === 200) {
      toast({
        title: 'Tiedot päivitetty.',
        description: 'Laitetiedot päivitetty onnistuneesti',
        status: 'success',
        duration: 5000,
        isClosable: true
      });
      return BackendApi.completeMessage(props.ticket.id, hash);
    }
  };

  const onChange = (value: string, field: string) => {
    if (invalidDataArray.includes(field)) {
      setInvalidDataArray(invalidDataArray.splice(invalidDataArray.indexOf(field), 1));
    }

    setDataObject({
      ...dataObject,
      [field]: value
    });
  };

  useEffect(() => {
    if (isSubmitting) {
      const portalFields = correctPortalObj();

      const softRquiredFields = portalFields.reduce((fields: string[], field) => {
        if (!dataObject[field.value] && field.customerPortal.type === 'require') {
          fields.push(field.name);
        }
        return fields;
      }, []);
      const hardRequiredFields = portalFields.reduce((fields: string[], field) => {
        if (!dataObject[field.value] && field.customerPortal.type === 'hard-require') {
          fields.push(field.name);
        }
        return fields;
      }, []);

      const lengthCheckedFields = portalFields.reduce((fields: any[], field: any) => {
        if (
          (dataObject[field.value] &&
            (!dataObject[field.value].match(/^[0-9]+$/) || dataObject[field.value].length !== 5) &&
            field.customerPortal.name === 'malli') ||
          (dataObject[field.value] &&
            (!dataObject[field.value].match(/^[0-9]+$/) ||
              dataObject[field.value].length < 9 ||
              dataObject[field.value].length > 10) &&
            field.customerPortal.name === 'sarjanumero')
        ) {
          fields.push({
            name: field.name,
            requiredLength: field.customerPortal.name === 'malli' ? '5' : '9-10'
          });
        }
        return fields;
      }, []);

      setInvalidDataArray(softRquiredFields);
      setRequiredDataArray(hardRequiredFields);
      setInvalidLengthArray(lengthCheckedFields);
      setValuesChecked(true);
    }
    if (valuesChecked) {
      if (requiredDataArray.length > 0 || invalidLengthArray.length > 0 || invalidLengthArray.length > 0) {
        setHardRequireModalOpen(true);
      }
      if (requiredDataArray.length < 1 && invalidDataArray.length > 0) {
        onOpen();
      } else if (requiredDataArray.length < 1 && invalidDataArray.length < 1 && invalidLengthArray.length < 1) {
        sendData();
      }
    }

    if (isSending && modalWarningIgnored) {
      sendData();
    }
  }, [isSubmitting, valuesChecked, isSending, modalWarningIgnored]);

  const sendComment = async (comment: Pick<Comment, 'content' | 'files'>) => {
    const messageData = new FormData();
    messageData.append('content', comment.content);
    if (!_.isEmpty(comment.files)) {
      for (var i = 0; i < comment.files!.length; i++) {
        let file = comment.files![i];
        messageData.append('files[' + i + ']', file);
      }
    }

    const result = await BackendApi.uploadComment(props.ticket.id, messageData, getUrlSearchParam('hash'));
    setIsSending(false);
    setTimeout(() => props.fetchTickets(), 3000);
    if (result.status === 200 || result.status === 201) {
      toast({
        title: 'Kommentti lähetetty.',
        description: 'Kommentti lähetetty onnistuneesti',
        status: 'success',
        duration: 5000,
        isClosable: true
      });
    }
  };

  const titleStrip = props.ticket.title.startsWith('Customer-portal', 0)
    ? props.ticket.title.substring(24)
    : props.ticket.title;

  const fetchFile = (attachmentId: string) => {
    return `${ApiConfig.getConfig().PORTAL_API_URL}/files/${attachmentId}`;
  };

  const accordionRef = useRef(null);

  useEffect(() => {
    if (props.selectedTicket === props.ticket.id.substring(3) && accordionRef?.current) {
      setTimeout(() => {
        (accordionRef.current as any).scrollIntoView?.();
      }, 100); // could not find another way to make it wait for accordion to be ready
    }
  }, [props.selectedTicket, accordionRef]);

  return (
    <div className="ticketContainer">
      <Accordion
        defaultIndex={props.selectedTicket === props.ticket.id.substring(3) ? [0] : []}
        allowToggle
        allowMultiple
        ref={accordionRef}
      >
        <AccordionItem bg="red">
          <AccordionButton className="ticketAccordionContainer">
            <Box flex="1" textAlign="left">
              <div style={{ width: '80%', display: 'inline-block' }}>
                <Heading as="h4" className="ticketTitle">
                  {titleStrip}
                </Heading>
                <p>{`Viimeisin viesti: ${
                  props.ticket.comments && props.ticket.comments.length > 0
                    ? dayjs
                        .unix(props.ticket.comments[props.ticket.comments.length - 1].created)
                        .format('DD.MM.YYYY hh:mm')
                    : ''
                }`}</p>
              </div>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pt={0} pl={0} pr={0} pb={0}>
            <div className="ticketInnerContainer" style={{ padding: '10px' }} id={props.ticket.id}>
              {props.ticket.portalData.map((dataObj: any) => {
                return (
                  <>
                    {dataObj ? (
                      <>
                        <Heading as="h4" size="lg" className="spacing15px">
                          <InfoIcon />
                          <span className="portalNewTicketHeading"> {dataObj.displayName} </span>
                        </Heading>
                        <Box mt={2} boxSize="sm" height="180px">
                          <Image src={dataObj.customerPortalHelpers?.helperImageUrl} />
                        </Box>
                        <Box mb={2}>
                          <InfoOutlineIcon /> {dataObj.customerPortalHelpers?.helperText}
                        </Box>
                        <div>
                          {dataObj.fields?.map((data: any, index: number) => {
                            const { customerPortal } = data;
                            const required =
                              customerPortal && ['hard-require', 'require'].includes(customerPortal.type);
                            return (
                              <>
                                <Stack spacing="4" align="stretch">
                                  <FormControl isRequired={required}>
                                    <FormLabel htmlFor={data.name}>{data.name}</FormLabel>
                                    <InputGroup>
                                      <InputLeftAddon children={index + 1} />
                                      <Input
                                        className="customerPortalInput"
                                        size="md"
                                        w="85%"
                                        id={data.name}
                                        height="40px"
                                        value={dataObject[data.value]}
                                        style={{ backgroundColor: 'white' }}
                                        onChange={(e) => onChange(e.target.value, customerPortal.name)}
                                      />
                                    </InputGroup>
                                  </FormControl>
                                </Stack>
                              </>
                            );
                          })}
                        </div>
                        <Button
                          isLoading={isSubmitting}
                          onClick={() => setIsSubmitting(true)}
                          bg="blue"
                          colorScheme="blue"
                          mt="2"
                          className="portalButton"
                        >
                          Lähetä
                        </Button>
                      </>
                    ) : undefined}
                  </>
                );
              })}

              <Divider mt={2} mb={2} />
              {props.ticket.attachments ? (
                <Heading as="h4" size="lg" className="spacing15px">
                  <AttachmentIcon />
                  <span className="portalNewTicketHeading">
                    Liitteet ({props.ticket.attachments ? props.ticket.attachments.length : 0})
                  </span>
                </Heading>
              ) : (
                ''
              )}
              <Stack spacing="4">
                <UnorderedList>
                  {props.ticket.attachments
                    ? props.ticket.attachments.map((attachment: any) => {
                        return (
                          <Link href={fetchFile(attachment)} isExternal>
                            <ListItem>
                              {attachment}
                              <ExternalLinkIcon />
                            </ListItem>
                          </Link>
                        );
                      })
                    : null}
                </UnorderedList>
              </Stack>
              <Divider mt={2} mb={2} />
              <Heading as="h4" size="lg" className="spacing15px">
                <ChatIcon />
                <span className="portalNewTicketHeading">
                  Kommentit ({props.ticket.comments ? props.ticket.comments.length : 0})
                </span>
                <Button
                  style={{ float: 'right' }}
                  leftIcon={<SpinnerIcon />}
                  colorScheme="blue"
                  isLoading={isUpdatingTickets}
                  onClick={() => props.fetchTickets()}
                >
                  Hae uudet kommentit
                </Button>
              </Heading>
              <CommentSection sendComment={sendComment} comments={props.ticket.comments} />
            </div>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsSubmitting(false);
          setValuesChecked(false);
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Varmistus</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Haluatko varmasti lähettää nämä kentät tyhjinä vaikka niitä vaaditaan?
            <div style={{ marginTop: '10px' }}>
              {invalidDataArray.map((field) => {
                return <span style={{ color: 'red' }}>{field}</span>;
              })}
            </div>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={() => {
                setIsSubmitting(false);
                setValuesChecked(false);
                setModalWarningIgnored(false);
                onClose();
              }}
              className="portalPositiveButton"
              mr={3}
            >
              Palaa muokkaamaan
            </Button>
            <Button
              onClick={() => {
                onClose();
                setIsSubmitting(false);
                setValuesChecked(false);
                setModalWarningIgnored(true);
                setIsSending(true);
              }}
              bg="red"
              className="portalRedButton"
              colorScheme="red"
            >
              Lähetä silti
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={hardRequireModalOpen}
        onClose={() => {
          setIsSubmitting(false);
          setValuesChecked(false);
          setHardRequireModalOpen(false);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            Lomakkeessa on puuttuvia vaadittuja kenttiä
            <div style={{ marginTop: '10px' }}>
              {requiredDataArray.map((field) => {
                return <span style={{ color: 'red' }}>{field}</span>;
              })}
            </div>
            {invalidLengthArray.length > 0 && (
              <div>
                {invalidLengthArray.map((field: any) => {
                  return (
                    <span style={{ color: 'red' }}>{`Ole hyvä ja tarkista, että ${field.name} on ${
                      field.name === 'Malli' ? `tarkalleen` : ``
                    } ${field.requiredLength} numeroa`}</span>
                  );
                })}
              </div>
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={() => {
                setIsSubmitting(false);
                setValuesChecked(false);
                setHardRequireModalOpen(false);
              }}
              bg="blue"
              colorScheme="blue"
              className="portalPositiveButton"
              mr={3}
            >
              Palaa muokkaamaan
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default TicketListItem;

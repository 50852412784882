import type { Language } from 'src/types/language';

const LANG_LIST: Language[] = [
  { id: 'fi', flag: 'FI', name: 'Suomi', momentLocale: 'fi' },
  { id: 'en', flag: 'GB', name: 'English', momentLocale: 'en' }
];

export function getLanguages(): Language[] {
  return LANG_LIST;
}
